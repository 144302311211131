import React, { Component } from 'react';
import { connect } from 'react-redux'

import { isEmail, isEmpty } from '../utils'
import { sendEmail }  from '../api'
import { doLogin } from '../actions/SessionActions'
import Error from '../components/Error'
import CountDownButton from '../components/CountDownButton'

class Login extends Component {
  state = {
    email: '',
    code: '',
    message: '',
    step: 0,
    loading: false
  }

  sendEmail = (e) => {
    e.preventDefault()
    this.setState({loading: true, message: ''})
    let email = this.state.email.trim().toLowerCase()

    sendEmail(email).then(() => this.setState({step: 1})).catch(this.onError).finally(this.loading)
  }

  login = (e) => {
    e.preventDefault()
    let email = this.state.email.trim().toLowerCase()
    let code = parseInt(this.state.code, 10)

    this.props.doLogin(email, code).catch(this.onError).finally(this.loading)
  }

  onError = (e) => {
    this.setState({ message: e.data })
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  loading = () => this.setState({ loading: !this.state.loading })
  _disabled = () => this.state.loading || !isEmail(this.state.email)

  render() {
    return (
      <div className="cs-login cs-primary-bg text-white">
        <div id="title">
          <img src="assets/logo.png" alt="kamisushi" />
          <h3 className="mt-3">Repartidores</h3>
        </div>
        <div className="container mt-5">
          <Error className="cs-error-margin" message={this.state.message} visible={!isEmpty(this.state.message)} />
          {this.state.step === 0 ?
            <EmailStep email={this.state.email} onChange={this.onChange} submit={this.sendEmail}
              loading={this.state.loading} disabled={this._disabled()} /> :
            <CodeStep code={this.state.code} email={this.state.email} onChange={this.onChange}
              loading={this.state.loading} submit={this.login}
              resend={this.sendEmail}
              back={() => this.setState({step: 0})} />}
        </div>
       </div>
    );
  }
}

const EmailStep = ({ email, onChange, submit, disabled, loading}) => (
  <form onSubmit={submit}>
    <div className="form-group">
      <input id="email" type="email" className="form-control form-control-lg" placeholder="correo@electronico.cl"
        value={email} onChange={onChange} disabled={loading}/>
      <small className="font-italic">*Enviaremos un codigo a tu correo que debes ingresar.</small>
    </div>
    <button className="btn btn-danger btn-block btn-lg cs-button" disabled={disabled}>Enviar Correo</button>
  </form>
)

const CodeStep = ({ code, onChange, email, submit, loading, resend, back}) => (
  <form onSubmit={submit}>
    <div className="form-group">
      <input id="code" type="number" className="form-control form-control-lg" placeholder="Codigo"
        value={code} onChange={onChange} disabled={loading} />
      <small className="font-italic">*Escribe el codigo enviado a {email}, este codigo solo funciona una vez, en caso de error, presiona reenviar codigo.</small>
    </div>
    <button className="btn btn-danger btn-block btn-lg cs-button" disabled={isEmpty(code)}>Entrar</button>
    <CountDownButton onClick={resend}>Reenviar Codigo</CountDownButton>
    <button className="btn btn-link btn-block btn-lg cs-button text-white" type="button"
      onClick={back} >Volver</button>
  </form>

)

const mapStateToProps = state => {
  return {
    token: state.session.token,
    host: state.session.host
  }
}

const mapDispatchToProps = dispatch => {
  return {
    doLogin: (email, code) => dispatch(doLogin(email, code))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)