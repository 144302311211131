import { version } from "../package.json";

let _host = 'https://api.clan28.cl'

if (process.env.NODE_ENV === 'development') {
  //_host = 'https://jengibre-sandbox.herokuapp.com'
  _host = 'http://localhost:8000'
}

export const host = _host
export const info = {
  version
}