import { CREATE_SESSION, DESTROY_SESSION, SET_USUARIO } from './Types'
import { login, logout, session } from '../api'

export function doLogin(email, code) {
  return (dispatch) => login(email, code).then(res => {
    if (res.status === 'success') {
      dispatch({
        type: CREATE_SESSION,
        token: res.data
      })
    } else {
      return Promise.reject(res)
    }
  })
}

export const doLogout = () => (dispatch) => logout().finally(() => dispatch({
    type: DESTROY_SESSION
}))

export const getUsuario = () => (dispatch) => session().then(res => {
  if (res.status !== 'success') {
    return Promise.reject(res)
  }
  dispatch({
    type: SET_USUARIO,
    session: res.data
  })
})

export const fijarSucursal = (id) => (dispatch) => { 

}