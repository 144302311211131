import { store as _store } from '../Store'
import { host } from "../config";

export function get(path) {
  return request(path, 'GET')
}

export function post(path, body) {
  return request(path, 'POST', body)
}

export function del(path) {
  return request(path, 'DELETE')
}

function request(path, method, body) {
  let { token } = _store.getState().session
  return fetch(host + path, {
    method,
    headers: {
      'API-TOKEN': token
    },
    body: body ? JSON.stringify(body) : undefined
  }).then(response)
}

const response = (r) => {
  if (r.status === 200) {
    return r.json()
  }
  return Promise.reject(r)
}