import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import localForage from 'localforage'
import reducers from './reducers'

const persistConfig = {
  key: 'temaki-app',
  storage: localForage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(persistedReducer, applyMiddleware(thunkMiddleware))
const persistor = persistStore(store)

export {
  store, persistor
}